<template>
  <div class="pagination">
    <ul class="pagination-list">
      <li v-for="i in pagination" :key="i" class="pagination-item">
        <button 
          class="pagination-btn"
          :class="{ 
            'active-btn-pagination': i === (currentPage + 1),
            'pagination--dots': i === '...'}" 
          :disabled="i === '...'" 
          @click="setPage(i)" 
        >
          {{ i }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>


export default {
  name: "CPagination",
  props: {
    totalPages: {
      default: null,
      required: true
    },
    currentPage: {
      default: 0,
      required: true
    },
  },
  computed: {
    pagination() {
      const delta = 2;
      const left = this.currentPage - delta;
      const right = this.currentPage + delta + 1;
      const range = [];
      const rangeWithDots = [];
      let l;

      for (let i = 1; i <= this.totalPages; i++) {
        if (i === 1 || i === this.totalPages || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
  methods: {
    setPage(page) {
      this.$emit('update:currentPage', page); 
    },
  }

};

</script>

<style scoped lang="scss">
.pagination {
  margin: 50px 0 0 0;
  margin-left: 10px;
  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;

    & li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-btn {
    border: 1px solid var(--n-layout-sidebar-color);
    background: white;
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  &--dots {
    border: none;
    background: none;
    font-size: 25px;
  }
}
.active-btn-pagination {
  background: var(--n-layout-sidebar-color);
  color: white;
}
</style>
