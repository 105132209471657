import CCompanyCreate from 'components/create-company/Index'
import CSettingsEdit from 'components/edit-settings/Index'
import CShowApplication from 'components/show-application/Index'
import CShowFaq from 'components/show-faq/Index'
import CCreateFaq from 'components/create-faq/Index'
import CCreateDepartment from 'components/create-department/Index'
import CDepartmentAddUsers from 'components/department-add-users/Index'
import CEditPromo from 'components/edit-promo/Index'
import CCreatePromo from 'components/create-promo/Index'
import CPromoAddUsers from 'components/promo-add-users/Index'
import CSheet from 'components/sheet/Index.vue'
import CCreateFilial from 'components/create-filial/Index'
import CFilialCreateEditGoogleMap from 'components/filial-create-edit-google-map/Index'
import CFilialAddUsers from 'components/filial-add-users/Index'
import CAccessBlockEdit from 'components/edit-access-block/Index'
import CPagination from './c-pagination/CPagination.vue'

export default {
  CCompanyCreate,
  CSettingsEdit,
  CShowApplication,
  CShowFaq,
  CCreateFaq,
  CCreateDepartment,
  CDepartmentAddUsers,
  CEditPromo,
  CCreatePromo,
  CPromoAddUsers,
  CSheet,
  CCreateFilial,
  CFilialCreateEditGoogleMap,
  CFilialAddUsers,
  CAccessBlockEdit,
  CPagination
}
